.deletion_request_form .ctn .title {
  padding: 20px 0px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff1616;
  text-align: center;
}
.deletion_request_form .ctn .form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.deletion_request_form .ctn .form label {
  font-size: 18px;
  color: rgb(73, 73, 73);
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
}
.deletion_request_form .ctn .form input[type="text"],
textarea {
  border: none;
  color: rgb(73, 73, 73);
  border: 1px solid rgb(170, 170, 170);
  font-size: 18px;
  padding: 10px;
  border-radius: 22px;
  transition: var(--transitiom);
  width: 100%;
}
.deletion_request_form .ctn .form input[type="text"],
textarea:focus {
  outline: none;
}
.deletion_request_form .ctn .form .error {
  font-size: 18px;
  color: #ff1616;
  display: block;
  padding-top: 4px;
}
.deletion_request_form .ctn .form textarea {
  height: 140px;
}
.deletion_request_form .ctn .form input[type="submit"] {
  border: none;
  color: rgb(229 229 229);
  font-size: 15px;
  background-color: #ff1616;
  padding: 15px 22px;
  border-radius: 22px;
  cursor: pointer;
  transition: var(--transitiom);
  width: 100%;
  margin-bottom: 20px;
}
/* Small */
@media (min-width: 768px) {
  .deletion_request_form .ctn .title {
    font-size: 28px;
  }
}
.request_success {
  height: calc(100vh - 80px);
}
.request_success .ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.request_success .ctn .title {
  padding: 20px 0px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #4cbe00;
  text-align: center;
}
@media (min-width: 768px) {
  .request_success .ctn .title {
    font-size: 28px;
  }
}