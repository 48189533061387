.attribution_page {
  padding-bottom: 20px;
}
.attribution_page .ctn .title {
  padding: 26px 0px;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff1616;
}
.attribution_page .ctn .t_des p {
  font-size: 20px;
  margin-bottom: 20px;
  color: rgb(73, 73, 73);
}
.attribution_page .ctn .t_des a {
  display: inline-block;
  color: #ff1616;
}
.attribution_page .ctn .t_des a:hover {
  text-decoration: underline;  
}
/* Medium */
@media (min-width: 992px) {
  .attribution_page {
    padding-bottom: 40px;
  }
  .attribution_page .ctn .t_des p {
    margin-bottom: 20px;
  }
}