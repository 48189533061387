@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  --transitiom: all 0.3s;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: initial;
  display: block;
}
/* a:hover {
  cursor: default;
} */
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  color: var(--colo-white);  
} 
ul {
  list-style: none;
  margin: 0;
  padding: 0;
} li {
  margin: 0;
  padding: 0;
}
button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

/* start container */
.ctn {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
} 
/*s end container */

/* start media query  */
/* Small */
@media (min-width: 768px) {
  .ctn {
      width: 750px;
      /* background-color: burlywood; */
  }
}
/* Medium */
@media (min-width: 992px) {
  .ctn {
      width: 970px;
      /* background-color: brown; */
  }
}
  /* Large */
@media (min-width: 1200px) {
  .ctn {
      width: 1170px;
      /* background-color: blue; */
  }
}
/* end media query */