.nav2_page {
  background-color: #ff1616;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  margin-bottom: 2px;
  position: sticky;
  top: 0px;
  z-index: 3;
}
.nav2_page .ctn .ab {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}
.nav2_page .ctn .ab .logo {
  cursor: pointer;
}
.nav2_page .ctn .ab .logo img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.nav2_page .ctn .ab .icon {
  color: white;
  font-size: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}