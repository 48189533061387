.nav_page {
  background-color: #ff1616;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  margin-bottom: 2px;
  position: sticky;
  top: 0px;
  z-index: 3;
}
.nav_page .ctn .ab {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}
.nav_page .ctn .ab .logo img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.nav_page .ctn .ab .icon {
  color: white;
  font-size: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav_page .ctn .ab .menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 82px;
  background-color: #ff1616;
  width: 100%;
  left: 0;
  transition: var(--transitiom);
  z-index: 4;
}
.nav_page .ctn .ab .close {
  left: -100%;
  opacity: 0;
}
.nav_page .ctn .ab .open {
  left: 0%;
  opacity: 1;
}
.nav_page .ctn .ab .menu li {
  padding: 20px 15px;
  color: white;
  text-transform: capitalize;
  font-size: 20px;
  cursor: pointer;
  transition: var(--transitiom);
  border-radius: 7.5px;
}
.nav_page .ctn .ab .menu li:hover {
  background-color: rgba(255, 255, 255, 0.248);
}
/* Small */
@media (min-width: 768px) {
  .nav_page .ctn .ab .icon {
    display: none;
  }
  .nav_page .ctn .ab .menu {
    position: static;
    flex-direction: row;
    opacity: 1;
    width: fit-content;
  }
  .nav_page .ctn .ab .menu li {
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }
}