.download_app {
  background-color: rgb(241 241 241);
}
.download_app .ctn .title {
  padding: 26px 0px;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff1616;
}
.download_app .ctn .ab_content {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
}
.download_app .ctn .ab_content .t_des {
  flex-shrink: 0;
  width: 100%;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download_app .ctn .ab_content .t_des h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #ff1616;
}
.download_app .ctn .ab_content .t_des p {
  font-size: 20px;
  margin-bottom: 20px;
  color: rgb(73, 73, 73);
}
.download_app .ctn .ab_content .img {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.download_app .ctn .ab_content .img img {
  display: block;
  width: 100%;
  border-radius: 15px;
}
/* Medium */
@media (min-width: 992px) {
  .download_app .ctn .title {
    padding: 40px 0px;
    font-size: calc(28px * 1.2);
  }
  .download_app .ctn .ab_content {
    column-gap: 20px;
    flex-direction: row-reverse;
    padding: 0px 0px 40px;
  }
  .download_app .ctn .ab_content .t_des {
    width: calc(60% - 10px);
    background-color: transparent;
  }
  .download_app .ctn .ab_content .img {
    width: calc(40% - 10px);
  }
  .download_app .ctn .ab_content .t_des h1 {
    font-size: calc(20px * 1.5);
    font-weight: 600;
    margin-bottom: 20px;
  }
  .download_app .ctn .ab_content .t_des p {
    margin-bottom: 20px;
  }
}
/* Larg */
@media (min-width: 1200px) {
  .download_app .ctn .ab_content {
    column-gap: 40px;
  }
  .download_app .ctn .ab_content .t_des {
    width: calc(50% - 20px);
  }
  .download_app .ctn .ab_content .img {
    width: calc(50% - 20px);
  }
}