.servises_page {
  background-color: rgb(241 241 241);
}
.servises_page .ctn .title {
  padding: 26px 0px;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff1616;
}
.servises_page .ctn .cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  padding: 0px 0px 26px;
}
.servises_page .ctn .cards .card {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 22px;
}
.servises_page .ctn .cards .card .ab_icon {
  width: 100px;
  height: 100px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff1616;
  color: white;
  border-radius: 50%;
  margin-bottom: 20px;
}
.servises_page .ctn .cards .card h2 {
  font-size: 24px;
  font-weight: 600;
  color: rgb(73, 73, 73);
  text-align: center;
}
/* Medium */
@media (min-width: 992px) {
  .servises_page .ctn  .title {
    padding: 40px 0px;
    font-size: calc(28px * 1.2);
  }
  .about_page .ctn .ab_content .t_des h1 {
    font-size: calc(20px * 1.5);
  }
  .servises_page .ctn .cards {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    padding: 0px 0px 40px;
  }
  .servises_page .ctn .cards .card {
    padding: 20px;
  }
}
/* Larg */
@media (min-width: 1200px) {
  .servises_page .ctn .cards {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}