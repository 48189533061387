.data_deletion_instruction {
  padding: 80px 0px;
}
.data_deletion_instruction .ctn section {
  margin-bottom: 50px;
}
.data_deletion_instruction .ctn section :last-child {
  margin-bottom: 0px;
}
.data_deletion_instruction .ctn section h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.data_deletion_instruction .ctn section p {
  font-size: 18px;
  margin-bottom: 20px;
}