.partnerships_page .ctn .title {
  padding: 26px 0px;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff1616;
}
.partnerships_page .ctn .cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 0px 0px 26px;
}
.partnerships_page .ctn .cards .card {
  background-color: rgb(241 241 241);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 22px;
  overflow: hidden;
}
.partnerships_page .ctn .cards .card .img {
  width: 100%;
  height: 160px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff1616;
  margin-bottom: 20px;
  background-size: cover;
}
.partnerships_page .ctn .cards .card h2 {
  font-size: 24px;
  font-weight: 600;
  color: rgb(73, 73, 73);
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
}
/* Medium */
@media (min-width: 992px) {
  .partnerships_page .ctn  .title {
    padding: 40px 0px;
    font-size: calc(28px * 1.2);
  }
  .about_page .ctn .ab_content .t_des h1 {
    font-size: calc(20px * 1.5);
  }
  .partnerships_page .ctn .cards {
    padding: 0px 0px 40px;
  }
}