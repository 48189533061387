.bg_alerts {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000e8;
  top: 0px;
  left: 0px;
  transition: var(--transitiom);
  z-index: 2;
  opacity: 1;
}
.bg_alerts_open {
  z-index: 2;
  opacity: 1;
}
.bg_alerts_close {
  z-index: -1;
  opacity: 0;
}