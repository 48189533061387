.landing {
  width: 100%;
  height: fit-content;
  background-image: url('https://images.pexels.com/photos/4391635/pexels-photo-4391635.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  overflow: hidden;
}

.landing .effect {
  width: 100%;
  height: 100%;
  background-color: #000000d5;
  padding: 40px 0 0px;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.landing .effect .ctn {
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.landing .effect .ctn .ab_titles {
  color: white;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing .effect .ctn .ab_titles section h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.landing .effect .ctn .ab_titles section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.landing .effect .ctn .ab_titles .content p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;
}

.landing .effect .ctn .img img {
  display: block;
  width: 100%;
  opacity: 0.8;

}

/* Small */
@media (min-width: 768px) {
  .landing .effect .ctn .img img {
    display: none;
  }
}

/* Medium */
@media (min-width: 992px) {
  .landing {
    height: calc(100vh - 82px);
  }

  .landing .effect {
    padding: 0;
  }

  .landing .effect .ctn {
    flex-direction: row;
  }

  .landing .effect .ctn .ab_titles {
    flex-shrink: 0;
    width: calc(60% - 10px);
  }

  .landing .effect .ctn .ab_titles section h1 {
    font-size: calc(28px * 1.5);
  }

  .landing .effect .ctn .ab_titles section h2 {
    font-size: calc(20px * 1.5);
  }

  .landing .effect .ctn .ab_titles .content p {
    font-size: 20px;
  }

  .landing .effect .ctn .img {
    flex-shrink: 0;
    width: calc(40% - 10px);
    height: 100%;
    display: flex;
    align-items: center;
  }

  .landing .effect .ctn .img img {
    display: block;
    width: 100%;
    transform: scale(-1, 1);
  }
}

/* Large */
@media (min-width: 1200px) {
  .landing .effect .ctn .ab_titles {
    width: calc(50% - 10px);
  }

  .landing .effect .ctn .img {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .landing .effect .ctn .img img {
    width: 65%;
  }
}