.footer_page {
  background-color: rgb(37, 37, 37);
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.footer_page .ctn .ab_header_nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 20px 0px;
  justify-content: space-between;
}
.footer_page .ctn .ab_header_nav .title {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
}
.footer_page .ctn .ab_header_nav img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.footer_page .ctn .deteals {
  display: flex;
  flex-direction: column;
  gap: 40px 40px;
  padding: 40px 0px;
}
.footer_page .ctn .deteals ul li {
  margin-bottom: 20px;
}
.footer_page .ctn .deteals ul :last-child {
  margin: 0;
}
.footer_page .ctn .deteals ul li h1 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  color: white;
}
.footer_page .ctn .deteals ul li,a {
  font-size: 20px;
  color: rgb(214, 214, 214);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footer_page .ctn .deteals ul li .icon {
  font-size: 18px;
}
@media (min-width: 992px) {
  .footer_page .ctn .deteals {
    flex-direction: row;
  }
}